/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import Slider from "react-slick";
import Header from "../Components/Header";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import "./style.css";

import {
  DatingBack,
  iphonePoster,
  HowItWorkPoster1,
  HowItWorkPoster2,
  HowItWorkPoster3,
  GooglePlay,
  AppleStore,
  mainslider1,
} from "../Utils/Images";

import {
  Container,
  Container1,
  HomeDownloadiBeor,
  HomeHeaderImage,
  HomeIphonePoster,
  HomeIphonePosterHide,
  InnerWrapper,
  MainCover,
  HomeBanner,
  WhiteSpace,
  Wrapper,
  HowItWorks,
} from "../GobalStyle";
// import mainslider1 from "../Images/mainslider1.webp";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import ResetPhoneNumber from "./ResetPhoneNumber";
import ForgetPassword from "./ForgetPassword";
import styled from "styled-components";

export function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <BsChevronRight
      size={55}
      style={{
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: "20px",
        zIndex: 55,
        color: "#fff",
        opacity: 0.4,
      }}
      onClick={onClick}
    />
  );
}

export function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <BsChevronLeft
      size={55}
      style={{
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: "20px",
        zIndex: 55,
        color: "#fff",
        opacity: 0.4,
      }}
      onClick={onClick}
    />
  );
}

export default function Home() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    pauseOnHover: false,
  };
  const slidersettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    pauseOnHover: false,
  };

  const queryToken = window?.location?.search?.substring(1)?.split("=")?.[1];
  const getParamsWordPassword = window?.location?.search?.includes("password");
  const getParamsWordToken = window?.location?.search?.includes("token");

  const HowItWorksArray = [
    {
      header: "Create A Profile",
      body: "Create your profile in seconds with our easy sign-up. ",
      image: HowItWorkPoster1,
    },

    {
      header: "Browse Photos",
      body: "Search our large member base with ease, with a range of preferences and settings.",
      image: HowItWorkPoster2,
    },

    {
      header: "Start Communicating",
      body: "Send a private message or interest to start communicating with members. It's your time to shine.",
      image: HowItWorkPoster3,
    },
  ];

  return (
    <>
      <Wrapper>
        {getParamsWordToken &&
          queryToken !== null &&
          queryToken !== undefined && <ResetPhoneNumber token={queryToken} />}

        {getParamsWordPassword &&
          queryToken !== null &&
          queryToken !== undefined && <ForgetPassword token={queryToken} />}

        <Container>
          <Header />
        </Container>
        <MainCover>
          <div>
            <Container1>
              <InnerWrapper>
                <HomeHeaderImage src={DatingBack} alt="Heading..." />
                <HomeIphonePosterHide src={iphonePoster} alt="Iphone" />

                <Storeicon>
                  <div className="getAppLinks">
                    <h1>GET THE APP</h1>
                    <a target="_blank" href="https://apps.apple.com/us/app/ibeor-dating-app/id1610780497">
                      <HomeDownloadiBeor src={AppleStore} alt="Download" />
                    </a>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.ibeor.official&hl=en_IN&gl=US">
                      <HomeDownloadiBeor src={GooglePlay} alt="Download" />
                    </a>
                  </div>
                </Storeicon>
              </InnerWrapper>
              <HomeIphonePoster
                style={{ top: "20px" }}
                src={iphonePoster}
                alt="Iphone"
              />
            </Container1>
          </div>
        </MainCover>
        {/* <WhiteSpace /> */}

        <HowItWorks>
          <div className="headerWrapper">
            <header>How It Works</header>
            <p>Get started on iBeor.com today in 3 simple steps:</p>
          </div>
          <div className="contentWrapper">
            {HowItWorksArray?.map((el) => (
              <div>
                <img src={el.image} alt="poster images" />
                <h1>{el.header}</h1>
                <p>{el.body}</p>
              </div>
            ))}
          </div>
        </HowItWorks>
        <SliderCustomWrapper>
          <Slider {...slidersettings}>
            <Imgslider>
              <img src={mainslider1} alt="slider1" />
            </Imgslider>

            <Imgslider>
              <img src={mainslider1} alt="slider1" />
            </Imgslider>
          </Slider>
        </SliderCustomWrapper>

        <HomeBanner>
          <div>
            <Container>
              <Text>
                <div className="headingleft">
                  <h2>Don't Stay Single - find Love Today on iBeor</h2>
                </div>
                <div className="headingright">
                  <h4>What's the Deal with Dating</h4>
                  <p>
                    Whatever your life circumstances or your intentions,the best
                    outcome is likely to depend on your perspective and
                    objectives for dating.it would be best to have some
                    guidelines and boundaries going into any dating relationship
                    to ensure you have the best chance of success.iBeor we have
                    the answers when it is time to Date. Download App Now!
                  </p>
                </div>
              </Text>
            </Container>
          </div>
        </HomeBanner>

        <Footer />
      </Wrapper>
    </>
  );
}

const SliderCustomWrapper = styled.div`
  width: 100%;
  height: 100%;

  div {
    display: block !important;
  }
`;
const Storeicon = styled.div`
  margin-top: 151px;
  margin-bottom: 61px;
  .getAppLinks {
    white-space: nowrap;
  }
  h1 {
    color: white;
    text-decoration: underline;
    font: normal normal normal 42px/1.2em "playfair display", serif;
    font-weight: 900;
    text-align: left;
  }
  img {
    margin-right: 20px;
  }
  @media (max-width: 1110px) {
    margin-top: 30px;
    margin-bottom: 50px;
  }
  @media (max-width: 820px) {
    margin-top: 0;
  }
`;

const Text = styled.div`
  width: 100%;
  min-height: 90vh;
  max-height: 100%;
  display: flex;
  justify-content: center;

  .headingleft {
    min-width: 700px;

    h2 {
      font-size: 40px;
      letter-spacing: 0.01em;
      font-family: poppins-semibold, poppins, sans-serif;
      line-height: 60px;
      font-weight: 700;
    }
    @media (max-width: 991px) {
      min-width: 100%;
    }
    @media (max-width: 600px) {
      min-width: 100%;

      h2 {
        font-size: 25px;
        width: 100%;
        line-height: 35px;
      }
    }
  }

  .headingright {
    h4 {
      font: normal normal normal 28px/1.25em "playfair display", serif;
      font-weight: bold;
      margin-top: 10px;
    }
    p {
      font-size: 20px;
      font: normal normal normal 20px/1.67em "playfair display", serif;
      font-weight: 500;
    }
    @media (max-width: 600px) {
      min-width: 100%;
      h4 {
        font-size: 25px;
        width: 100%;
      }
      p {
        font-size: 15px;
        color: #000;
      }
    }
  }

  @media (max-width: 1250px) {
    flex-wrap: wrap;
    margin-left: 150px;
    gap: 0px;
    width: 70%;
    min-height: 29vh;
  }
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    margin-left: 75px;
    min-height: 29vh;
  }
  @media (max-width: 600px) {
    flex-wrap: wrap;
    margin-left: 9px;
    gap: 0px;
    width: 100%;
    min-height: 19vh;
  }
`;
const Imgslider = styled.div`
  img {
    width: 100%;
    height: 870px;
    object-fit: cover;
    object-position: 50% 50%;
  }
`;
