import styled, { isStyledComponent } from "styled-components";
import styledComponents from "styled-components";
import { Africacover, image2, MainCoverImage1 } from "./Utils/Images";
// import Africacover from "./Images/Africacover.webp";
// import image2 from "./Images/image2.webp";

export const SliderInnerWrap = styledComponents.div`
display: flex;
justify-content: center;
width:100%;
padding:40px 0;


img {
  width: 381px;
  height: 481px;
  object-fit: cover;
  border-radius: 8px;
  margin:20px;
  
}

@media(max-width:992px){
  flex-direction:column;
  align-items: center;
  img {
    width: 300px;
    height: 400px;
  }
}



`;

export const CopyRight = styledComponents.div`

width:100%;
height:50px;
background: #333333bf;
display: flex;
justify-content: end;
align-items: center;
padding: 0 20px;

span {
    font-weight: 100;
    color: #ffffff;
    font-size: 18px;
}



`;

export const GetTheApp = styledComponents.div`

width: 100%;
margin:20px 0;
display: flex; 
gap: 30px;
h2 {
    color: #292929;
    font-size:28px;
    margin: 5px 0;
}
img {
    width:130px;
    margin: 5px 10px;
    cursor: pointer;
}

@media (max-width: 800px){
  display: block; 
}

`;

export const SingleLine = styledComponents.div`
width: 100%;
height: 1px;
background: #999999;


`;

export const SocialMediaIcons = styledComponents.div`

display: flex;

img {
  margin:  5px;
}
svg {
    color: #333;
    margin:5px;
    cursor: pointer;
}



`;

export const FooterWrapper = styledComponents.div`
width: 100%;
margin: auto;
display: flex;
justify-content: space-between;
padding: 20px;


ul{
    font-family: 'Montserrat', sans-serif;    
    font-size: 24px;
    font-weight:700;
    color:#292929;
    margin: 40px 0 20px 0;
padding: 0 5px;

}

li {
  font-family: "Playfair Display", serif;
    list-style: none;
    font-style:none;
    font-size: 18px;
    font-weight:400;
    margin: 10px 0 ;
    cursor: pointer;
padding: 0 5px;



}

@media (max-width: 992px) {
  ul{
    font-size: 18px;
    font-weight:700;
  }
li {
    font-size: 14px;
    font-weight:400;
  }
}

@media (max-width: 658px) {
flex-direction: column;
align-items: start;
ul{
  margin: 10px 0 0px 0;
}

li {
  display: flex;
  flex-direction: row;
  margin: 10px 0 ;

}

}
`;

export const ThirdSlider = styledComponents.div`

width:100%;
min-height:120vh;
max-height:100%;
padding:40px;
background: rgb(13, 79, 43);
// background:${({ color }) => color};


`;

export const SecondSlider = styledComponents.div`

width:100%;

min-height:120vh;
max-height:120vh;

padding:40px;
background: #0A2e5a;

`;

export const FirstSlider = styled.div`
  width: 100%;
  height: 800px;
  padding: 0 20px;
  background: ${(props) => props.color};
  @media (max-width: 991px) {
    height: 100%;
  }
`;

export const ThirdImage = styledComponents.img`
    width:25%;
height:600px;

    object-fit: cover;
`;

export const SecondImage = styledComponents.img`
width:50%;
height:600px;

object-fit: cover;



`;

export const FirstImage = styledComponents.img`

width:25%;
height:600px;
object-fit: cover;



`;

export const ThirdSection = styledComponents.div`

width:100%;
display: flex;


@media (max-width: 600px) {
flex-direction: column;
img {
  width:100%;
}
}
`;

export const Wrapper = styledComponents.div`
width:100%;
height:100%;
background:#e6e3e3cc;
`;

export const MainCover = styledComponents.div`

width:100%;
height:100%;
background: url(${MainCoverImage1});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;

@media(max-width:500px){
  height:100%;
}


`;
export const AfricaCover = styledComponents.div`
width:100%;
height:100%;
background-color:rgb(255, 64, 64);
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
@media(max-width:500px){
  height:100%;
}
`;
export const AfricaBanner = styledComponents.div`
width:100%;
height: 716px;
background: url(${Africacover});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;

@media(max-width:500px){
  height:100%;
}

`;
export const HomeBanner = styledComponents.div`
width:100%;
height:100%;
background: url(${image2});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;

@media(max-width:500px){
  height:100%;
}

`;
export const Topwrapper = styledComponents.div`
width:45%;
height:810px;
display:flex;
flex-direction:column;
gap:40px;
margin-left:410px;
padding-top: 30px;
@media(max-width: 1670px){
  margin-left: 290px;
}
@media(max-width: 1500px){
  margin-left: 240px;
}
@media(max-width: 1300px){
  margin-left:0px;
}
@media(max-width: 1400px){
  margin-left: 200px;
}

@media(max-width: 1110px){
  gap: 0px;
}

@media(max-width:1100px){
  margin-left:0px;
  width: 100%;
  height: 100%;
  margin-left: 0px;
}
@media(max-width:1070px){
  margin-left:0px;
  width: 100%;
  height: 100%;
  margin-left: 0px;
}
@media(max-width:900px){
  width: 100%;
  height: 100%;
  gap: 0;
}
@media(max-width:600px){
  width: 100%;
  height: 100%;
  gap: 0;
}


`;

export const InnerWrapper = styledComponents.div`
width:80%;
height:810px;
display: flex;
flex-direction:column;
align-items: center;
justify-content: center;
padding: 0 40px;



@media(max-width: 1450px){
  width: 60%;
}
@media (max-width: 1110px) {
width:100%;
height:100%;
padding: 0 10px;

}



`;

export const HomeHeaderImage = styledComponents.img`
width: 620px;
margin-top:100px;
padding-top: 50px;
@media (max-width: 1800px) {
width: 600px;
}
@media (max-width: 1680px) {
  width: 500px;
}

@media (max-width: 1250px) {
  width: 400px;
}
@media (max-width: 1024px) {
  width: 420px;
  margin-top:10px;
  }
 

  @media (max-width: 500px) {
    width: 100%;
    margin-top:0;
    }

`;

export const HomeDownloadiBeor = styledComponents.img`
width:200px;
margin-top: 40px;
cursor:pointer;
@media (max-width: 1250px) {
  width: 150px;
}
@media (max-width: 1120px) {
  width: 150px;
  margin-top: 0;
}
`;

export const HomeIphonePosterHide = styledComponents.img`
display:none;
justify-content: center;
@media (max-width: 1111px) {
  display: flex;
  width:300px;
  height:500px;
margin:20px 0;
margin: auto;

}

@media (max-width: 500px) {
  display: flex;
  width:250px;
  height:auto;
  margin:20px 0;
  margin: auto;
}

`;

export const HomeIphonePoster = styledComponents.img`
display: block;
position: absolute;
height:auto;
width:400px;
right: 300px;
top: 0;
bottom:0;
@media (max-width: 2150px) {
  width: 400px;
  right: 240px;
  }
@media (max-width: 1850px) {
  width: 400px;
  right: 220px;
  }
  @media (max-width: 1650px) {
width: 400px;
right: 180px;
}
@media (max-width: 1500px) {
  width: 400px;
  right: 170px;
  }
@media (max-width: 1400px) {
  width: 400px;
  right: 90px;
  }
  @media (max-width: 1300px){
    right: 50px;
    width: 400px;
   }
@media (max-width: 1272px) {
 right: 0px;
}
@media (max-width: 1111px) {
  display: none;
}



`;

export const WhiteSpace = styledComponents.div`

width:100%;
height:35px;



`;

export const Container = styledComponents.div`
width: 72%;
height:100%;
margin:auto;
@media(max-width: 925px){
  width: 95%;
}
`;

export const Container1 = styledComponents.div`
width:100%;
height:100%;
margin:auto;
position:relative;

@media (max-width: 1400px) {
  width:90%;
  
  }


@media (max-width: 600px) {
width:95%;
}
@media (max-width: 600px) {
  width:95%;
  margin: 0;
  }
  @media (max-width: 900px) {
 width:100%;
 margin: 0;
 }
`;

export const Heading = styledComponents.h1`
font-family: 'Noto Sans', sans-serif;
list-style: normal;
font-size: 35px;
line-height:46px;
font-weight: 700;
margin: 20px 0;
display: flex;
flex-direction: column;
align-items: center;




`;

export const FooterItems = styledComponents.div`
width:100%;
padding: 20px 10px;

heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Montserrat',sans-serif;
  list-style: none;
  font-size: 44px;
  font-weight: 500;
  margin: 0 0 20px 0;
  color:#8c331c;
}
h3 {
  display: flex;
flex-direction: column;
align-items: center;
  font-family: 'Montserrat',sans-serif;
    list-style: none;
    font-size: 44px;
    font-weight: 400;
    margin: 20px 0;
}
p{
  font-family: 'Noto Sans', sans-serif;
  list-style: normal;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8;
  margin: 30px 0 ;
  color:#000 ;
  padding:5px 0;


}
a{
  font-family: 'Noto Sans', sans-serif;
  list-style: normal;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin: 30px 0 ;
  color:#333;
}
label{
  font-family: 'Noto Sans', sans-serif;
  list-style: normal;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  margin: 30px 0 ;
  color:#000;
}
span {
  font-family: 'Noto Sans', sans-serif;
  list-style: normal;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  margin: 30px 0 ;
  color:#000;
}

li{
  font-family: 'Noto Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  display: inline-block;
  margin: 20px 0 ;
  color:#000;


 
}



ul {
  font-family: 'Noto Sans', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  display: inline-block;
  margin: 10px 0 ;
  color:#000;
}

h4 {
  font-family: 'Noto Sans', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  margin: 30px 0 ;
  color:#000;
}

h2{
    text-align:center;
    font-family: 'Noto Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin: 30px 0 ;
    color:#000;
}

h1 {
    text-align:center;
     font-family: 'Noto Sans', sans-serif;
    font-size: 50px;
    font-weight: 500;
    line-height: 55px;
    margin: 60px 0 ;
    color:#000;
}
h5{
    text-align:center;
     font-family: 'Noto Sans', sans-serif;
    font-size: 55px;
    font-weight: 700;
    line-height: 60px;
    margin: 60px 0 ;
    color:#333;
}


@media (max-width: 400px) {
  h3 {
      display: flex;
      flex-direction: column;
      align-items: center;
       font-family: 'Noto Sans', sans-serif;
      list-style: none;
      font-size: 34px;
      font-weight: 400;
      margin: 10px 0;
  }
  p{
     font-family: 'Noto Sans', sans-serif;
    list-style: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 20px 0 ;
    color:#333;
  
  }
  a{
       font-family: 'Noto Sans', sans-serif;
    list-style: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 20px 0 ;
    color:#333;
  }
  span{
    font-family: 'Noto Sans', sans-serif;
    list-style: normal;
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
    margin: 30px 0 ;
    color:#000;
  
  }
  
  li{
     font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    margin: 10px 0 ;
    color:#222;
  
  }
  
  ul {
     font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin: 10px 0 ;
    color:#000;
  }
  
  h4 {
     font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin: 20px 0 ;
    color:#000;
  }
  
  h2{
      text-align:center;
       font-family: 'Noto Sans', sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin: 20px 0 ;
      color:#000;
  }
  
  h1 {
      text-align:center;
       font-family: 'Noto Sans', sans-serif;
      font-size: 30px;
      font-weight: 500;
      line-height: 35px;
      margin: 30px 0 ;
      color:#000;
  }
  h5{
      text-align:center;
       font-family: 'Noto Sans', sans-serif;
      font-size: 32px;
      font-weight: 700;
      line-height: 38px;
      margin: 30px 0 ;
      color:#333;
  }
}

`;

export const SmallTextPTAG = styledComponents.div`
font-family: 'Noto Sans', sans-serif;
list-style: normal;
font-size: 16px;
font-weight: 400;
line-height: 1.8;

margin: 30px 0 ;
color:#000;

`;

export const HeaderLogoWrap = styledComponents.div`

width:100%;
display: flex;
justify-content: center;

img {
  width:200px;
  height:100%;
  margin:40px 0 10px 0;
  cursor: pointer;
  object-fit: contain;
}


.ContactUsLogo {
  width:30%;
  @media (max-width:500px){
    width:150px;
  margin:25px 0 5px 0;

  }
}

`;

export const FooterItemsBox = styledComponents.div`
width:65%;
margin: auto;
div{
    display: flex;
}
h2 {
    font-family: 'playfair display',sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin: 20px 30px 20px 0 ;
    color:#000;
}
span{
     font-family: 'Montserrat',sans-serif;
list-style: none;
font-size: 14px;
font-weight: 400;
line-height: 22px;
margin: 20px 0 ;
color:#333;

}

h1 {
    font-family: 'Montserrat',sans-serif;
    list-style: none;
    font-size: 29px;
    font-weight: 900;
    line-height: 50px;
    margin: 30px 0;
    color:#000;

}



`;

export const LogoWrapper = styledComponents.div`
 display: flex;
 justify-content: center;
 padding:20px;

`;

export const LinearColorBack = styledComponents.div`
width:100%;
margin:120px 0 20px 0;

background: radial-gradient(circle at 3.6751302083333335% 6.940104166666666%, #4D6001 0%, 11.55%, rgba(77,96,1,0) 35%), radial-gradient(circle at 92.96712239583333% 9.554036458333332%, #FAAA63 0%, 17.5%, rgba(250,170,99,0) 35%), radial-gradient(circle at 95.20670572916666% 90.48177083333333%, #C9C6FF 0%, 34.1%, rgba(201,198,255,0) 55%), radial-gradient(circle at 4.313151041666666% 93.34635416666667%, #97B49D 0%, 29.900000000000002%, rgba(151,180,157,0) 65%), radial-gradient(circle at 46.555989583333336% 11.50390625%, rgba(214,222,72,0.99) 0%, 25%, rgba(214,222,72,0) 50%), radial-gradient(circle at 51.121419270833336% 89.98697916666667%, #93C3FF 0%, 42%, rgba(147,195,255,0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255,255,255,0) 100%);


`;

export const FooterContainer = styledComponents.div`

width:60%;
height:100%;
margin : 0 auto;
@media(max-width:1310px){
  width:70%;
  
  }

  @media(max-width:1128px){
    width:80%;
    
    }

    @media(max-width:851px){
      width:90%;
      
      }


`;

export const MainFooterContainer = styledComponents.div`

width:70%;
height:100%;
margin : 0 auto;

@media(max-width:1310px){
  width:70%;
  
  }

  @media(max-width:1128px){
    width:80%;
    
    }

    @media(max-width:851px){
      width:90%;
      
      }


`;

export const SliderSectionContainer = styledComponents.div`

width:60%;
height:100%;
margin:auto;


@media(max-width:1200px){
width:65%;

}
@media(max-width:1100px){
  width:75%;
}

@media(max-width:969px){
  width:90%;
}

@media(max-width:825px){
  width:98%;
}

`;

export const HowItWorks = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;

  .headerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    header {
      font-family: "Playfair Display", serif;
      font-size: 53px;
      line-height: 73px;
      color: #000000;
      font-weight: 700;
    }

    p {
      font-family: "Playfair Display", serif;
      font-size: 28px;
      line-height: 66px;
      color: #000000;
      font-weight: 400;
      text-align: center;
    }
    @media (max-width: 820px) {
      header {
        font-size: 45px;
      }
    }
    @media (max-width: 620px) {
      header {
        font-size: 35px;
      }
    }
    @media (max-width: 420px) {
      header {
        font-size: 35px;
      }
      p {
        font-size: 23px;
        text-align: center;
        line-height: 36px;
        padding: 0px 10px;
      }
    }
  }

  .contentWrapper {
    display: flex;
    width: 60%;
    margin: auto;

    @media (max-width: 1420px) {
      width: 72%;
    }
    @media (max-width: 1520px) {
      width: 65%;
    }
    @media (max-width: 1260px) {
      width: 86%;
    }
    @media (max-width: 1060px) {
      width: 90%;
    }
    div {
      width: 100%;
      margin: 0 10px;

      img {
        width: 100%;
        height: 280px;
      }
      h1 {
        font-family: "Playfair Display", serif;
        font-size: 22px;
        line-height: 26px;
        color: #000000;
        font-weight: 700;
        text-align: center;
      }

      p {
        font-family: "Playfair Display", serif;
        font-size: 20px;
        line-height: 35px;
        color: #000000;
        font-weight: 400;
        text-align: center;
      }
    }
    @media (max-width: 820px) {
      flex-wrap: wrap;
    }
  }
  // @media (max-width: 820px) {
  //   height: 1300px;
  // }
`;
